<template>
  <panel-alert :title="title" isShowInfo isShowBottom width="580px" @onCloseClick="onCloseClick"
    @onConfirmClick="onConfirmClick">
    <template v-slot:content>
      <div class="add-patient-alert">
        <div class="add-patient-alert-content">
          <div class="key-value-item">
            <must-label isMust text="患者姓名"></must-label>
            <el-input placeholder="请输入" v-model="patientName" maxlength="50">
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label text="患者ID"></must-label>
            <el-input placeholder="请输入" v-model="patientID" maxlength="50">
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label isMust text="性别"></must-label>
            <switch-picker left="男" right="女" :inputIsPickLeft="isMale" @onPick="onGenderPick"></switch-picker>
          </div>
          <div class="key-value-item key-value-item-birthday">
            <must-label isMust text="出生日期"></must-label>
            <el-date-picker :append-to-body="false" v-model="birthday" type="date" placeholder="选择日期"
              :picker-options="birthdayPickOption">
            </el-date-picker>
          </div>
          <div class="key-value-item">
            <must-label isMust text="身高"></must-label>
            <el-input placeholder="请输入" v-model="height">
              <p slot="suffix" class="key-value-item-unit">cm</p>
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label isMust text="体重"></must-label>
            <el-input placeholder="请输入" v-model="weight">
              <p slot="suffix" class="key-value-item-unit">kg</p>
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label text="BMI"></must-label>
            <p class="key-value-item-bmi-value">{{ bmi }}</p>
          </div>
          <div class="key-value-item">
            <must-label text="颈围"></must-label>
            <el-input placeholder="请输入" v-model="neckCircumference">
              <p slot="suffix" class="key-value-item-unit">cm</p>
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label isMust text="婚姻状况"></must-label>
            <switch-picker left="未婚" right="已婚" :inputIsPickLeft="isUnMarried" @onPick="onMarriagePick"></switch-picker>
          </div>
          <div class="key-value-item">
            <must-label isMust text="手机号"></must-label>
            <el-input placeholder="请输入" :disabled="!!patientDetail" v-model="mobile" maxlength="20">
            </el-input>
          </div>
          <div class="key-value-item">
            <must-label text="学历"></must-label>
            <el-select v-model="selectedEducationType" :popper-append-to-body="false" placeholder="请选择">
              <el-option v-for="item in EDUCATION_ARRAY" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="key-value-item">
            <must-label text="职业"></must-label>
            <el-input placeholder="请输入" v-model="occupation"></el-input>
          </div>
          <div class="key-value-item">
            <must-label text="联系时间"></must-label>
            <switch-picker left="工作日" right="非工作日" :inputIsPickLeft="isWeekdayContact"
              @onPick="onContactTimePick"></switch-picker>
          </div>
          <div class="time-select-item">
            <el-select v-model="startTime" :popper-append-to-body="false" placeholder="开始时间"
              @change="onStartTimeChanged">
              <el-option v-for="(item, index) in durationArray" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
            <div class="time-select-item-line">-</div>
            <el-select v-model="endTime" :popper-append-to-body="false" placeholder="结束时间">
              <el-option v-for="(item, index) in durationArray" :disabled="isEarlierThanStartTime(item)" :key="index"
                :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="key-value-item">
            <must-label text="地址"></must-label>
            <el-cascader class="key-value-item-area" :props="areas" placeholder="省/市/区" v-model="areaIDArray"
              :append-to-body="false"></el-cascader>
          </div>
          <el-input class="address-item" placeholder="详细地址" maxlength="300" v-model="address">
          </el-input>
        </div>
        <div class="add-patient-alert-line"></div>
        <div class="add-patient-alert-content">
          <div class="key-value-item">
            <must-label isMust text="主诉病情"></must-label>
            <el-select v-model="selectedMedicalType" :popper-append-to-body="false" placeholder="请选择">
              <el-option v-for="item in MEDICAL_TYPE_ARRAY" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="key-value-item key-value-item-history">
            <must-label isMust text="既往病史"></must-label>
            <el-select v-model="selectedMedicalHistory" :popper-append-to-body="false" multiple collapse-tags
              placeholder="请选择">
              <el-option v-for="(item, index) in MEDICAL_HISTORY_ARRAY" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="key-value-item key-value-item-info">
            <must-label isMust text="病史信息"></must-label>
            <el-input type="textarea" placeholder="请输入" v-model="medicalInfo">
            </el-input>
          </div>
        </div>
        <div class="add-patient-alert-line"></div>
        <tag-view :patientDetail="patientDetail"></tag-view>
      </div>
    </template>
  </panel-alert>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import SwitchPicker from "@c/common/alert/panel-alert/panel-alert-add-patient/switch-picker.vue";
import MustLabel from "@c/common/alert/panel-alert/components/must-label.vue";
import TagView from "@c/common/alert/panel-alert/panel-alert-add-patient/tag-view.vue";
import { ValidateTool } from "@js/validate-tool.js";
import { DateTool } from "@js/date-tool.js";
import { computeBMI } from "@js/common-tool.js";
import { getObjectKeys, findKey } from "@js/object-tool.js";
import {
  Input,
  DatePicker,
  TimeSelect,
  Select,
  Option,
  Cascader,
} from "element-ui";

export default {
  components: {
    PanelAlert,
    SwitchPicker,
    MustLabel,
    TagView,
    //element
    elInput: Input,
    elDatePicker: DatePicker,
    elTimeSelect: TimeSelect,
    elSelect: Select,
    elOption: Option,
    elCascader: Cascader,
  },

  props: {
    patientDetail: Object,
  },

  data: function () {
    return {
      EDUCATION_ARRAY: getObjectKeys(EDUCATION_TYPE),
      MEDICAL_TYPE_ARRAY: ["COPD", "OSA", "CSA", "MSA", "NMD"],
      MEDICAL_HISTORY_ARRAY: [
        "心血管类慢病",
        "呼吸类慢病",
        "脑血管慢病",
        "糖尿病",
        "高血压",
        "肿瘤",
        "其他",
      ],
      birthdayPickOption: {
        disabledDate: (time) => {
          const MIN_BIRTHDAY = new Date("01/01/1900");
          const MAX_BIRTHDAY = new Date("12/31/2100");
          return time.getTime() < MIN_BIRTHDAY || time.getTime() > MAX_BIRTHDAY;
        },
      },
      patientName: undefined,
      patientID: undefined,
      isMale: undefined,
      birthday: new Date("01/01/1980"),
      height: undefined,
      weight: undefined,
      neckCircumference: undefined,
      isUnMarried: undefined,
      mobile: undefined,
      selectedEducationType: undefined,
      occupation: undefined,
      isWeekdayContact: true,
      startTime: "08:00",
      endTime: "20:00",
      //动态加载省市区列表
      areas: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, data } = node;
          const areaArray = await this.getAreaList(
            data == undefined ? 0 : data.value
          );
          const nodes = areaArray.map((item) => ({
            value: item.id,
            label: item.name,
            leaf: level >= 2,
          }));
          resolve(nodes);
        },
      },
      areaIDArray: undefined,
      address: undefined,
      medicalHistory: undefined,
      selectedMedicalType: undefined,
      selectedMedicalHistory: undefined,
      medicalInfo: undefined
    };
  },

  computed: {
    title() {
      return this.patientDetail ? "编辑信息" : "新建患者";
    },

    bmi() {
      return computeBMI(this.height, this.weight)
    },

    durationArray() {
      let durationArray = [];
      for (const hour of [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ]) {
        durationArray.push(`${hour}:00`);
      }
      return durationArray;
    },
    hospitalId() {
      return this.patientDetail ? this.patientDetail.hospitalId : this.$store.state.organizationModel?.id;
    }
  },

  created() {
    if (this.patientDetail) {
      this.assignment();
    }
  },

  methods: {
    onGenderPick(isMale) {
      this.isMale = isMale;
    },

    onMarriagePick(isUnMarried) {
      this.isUnMarried = isUnMarried;
    },

    onContactTimePick(isWeekdayContact) {
      this.isWeekdayContact = isWeekdayContact;
    },

    onStartTimeChanged() {
      const intStartHour = parseInt(this.startTime.split(":")[0]);
      const intEndHour = parseInt(this.endTime.split(":")[0]);
      if (intEndHour <= intStartHour) {
        this.endTime = "";
      }
    },

    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      try {
        this.validateEmpty("患者姓名", this.patientName);
        this.validateEmpty("性别", this.isMale);
        this.validateEmpty("出生日期", this.birthday);
        this.validateBirthday();
        this.validateEmpty("身高", this.height);
        this.validateNumber("身高", this.height);
        this.validateEmpty("体重", this.weight);
        this.validateNumber("体重", this.weight);
        if (!this.isEmpty(this.neckCircumference)) {
          this.validateNumber("颈围", this.neckCircumference);
        }
        this.validateEmpty("婚姻状况", this.isUnMarried);
        this.validateEmpty("手机号", this.mobile);
        this.validateMobile(this.mobile);
        if (this.startTime) {
          this.validateEmpty("结束时间", this.endTime);
        }
        if (!this.isEmpty(this.address)) {
          this.validateEmpty("省市区", this.areaIDArray);
        }
        this.validateEmpty("主诉病情", this.selectedMedicalType);
        this.validateEmpty("既往病史", this.selectedMedicalHistory.join(","));
        this.validateEmpty("病史信息", this.medicalInfo);
        this.addOrEditUser();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    //method
    async getAreaList(pid) {
      try {
        const params = {
          pid
        };
        return await this.$api.getAreaList(params);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async addOrEditUser() {
      try {
        const params = {
          id: this.patientDetail?.id ?? "",
          name: this.patientName,
          number: this.patientID ?? "",
          sex: this.isMale ? USER_GENDER.男 : USER_GENDER.女,
          birthday: DateTool.dateToStr(this.birthday, "YYYY-MM-DD"),
          height: this.height,
          weight: this.weight,
          bmi: this.bmi,
          neck: this.neckCircumference ?? "",
          marry: this.isUnMarried ? USER_MARRIAGE.未婚 : USER_MARRIAGE.已婚,
          mobile: this.mobile,
          education: EDUCATION_TYPE[this.selectedEducationType] ?? 0,
          occupation: this.occupation ?? "",
          contactType: this.isWeekdayContact
            ? USER_CONTACTTYPE.工作日
            : USER_CONTACTTYPE.非工作日,
          contactTime: `${this.startTime}-${this.endTime}`,
          areaId: this.areaIDArray == undefined ? "" : this.areaIDArray[2],
          address: this.address ?? "",
          hospitalId: this.hospitalId,
          illness: this.selectedMedicalType,
          ph: this.selectedMedicalHistory.join(","),
          phi: this.medicalInfo
        };
        const data = await this.$api.addOrEditPatient(params);
        const hudInfo = this.patientDetail ? "编辑成功！" : "新建成功！";
        this.$toast.showGreen(hudInfo);
        this.$emit("onSucceed", data);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    isEarlierThanStartTime(currentEndTime) {
      const intStartHour = parseInt(this.startTime.split(":")[0]);
      const currentEndHour = parseInt(currentEndTime.split(":")[0]);
      return currentEndHour <= intStartHour;
    },

    isEmpty(value) {
      return value == undefined || value === "";
    },

    validateBirthday() {
      var currentDate = new Date();
      var currentDayStr = DateTool.dateToStr(currentDate, "YYYY-MM-DD");
      var birthDayStr = DateTool.dateToStr(this.birthday, "YYYY-MM-DD");
      if (birthDayStr > currentDayStr) {
        throw `出生日期不能晚于今天`;
      }
    },

    validateMobile(mobile) {
      //字母数字符号
      if (!ValidateTool.isLetterNumberSymbol(mobile)) {
        throw "请输入正确的手机号";
      }
    },

    validateEmpty(type, value) {
      if (this.isEmpty(value)) {
        throw `${type}为必填，请检查！`;
      }
    },

    validateNumber(type, value) {
      if (isNaN(value) || value <= 0) {
        throw `${type}必须为正数`;
      }
      if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        throw `${type}最多两位小数`;
      }
      if (value > 300) {
        throw `${type}不能超过300`;
      }
    },

    assignment() {
      this.patientName = this.patientDetail.name;
      this.patientID = this.patientDetail.number;
      //isMale
      const sex = this.patientDetail.sex;
      switch (sex) {
        case USER_GENDER.男:
          this.isMale = true;
          break;
        case USER_GENDER.女:
          this.isMale = false;
          break;
        default:
          this.isMale = undefined;
      }
      //birthday
      const birthdayStr = this.patientDetail.birthday;
      this.birthday = birthdayStr ? new Date(birthdayStr) : null;
      this.height = this.patientDetail.height;
      this.weight = this.patientDetail.weight;
      this.neckCircumference = this.patientDetail.neck;
      //isUnMarried
      const marry = this.patientDetail.marry;
      switch (marry) {
        case USER_MARRIAGE.未婚:
          this.isUnMarried = true;
          break;
        case USER_MARRIAGE.已婚:
          this.isUnMarried = false;
          break;
        default:
          this.isUnMarried = undefined;
      }
      this.mobile = this.patientDetail.mobile;
      //education
      this.selectedEducationType = findKey(EDUCATION_TYPE, this.patientDetail.education)
      //occupation
      this.occupation = this.patientDetail.occupation
      //isWeekdayContact
      const contactType = this.patientDetail.contactType;
      switch (contactType) {
        case USER_CONTACTTYPE.工作日:
          this.isWeekdayContact = true;
          break;
        case USER_CONTACTTYPE.非工作日:
          this.isWeekdayContact = false;
          break;
        default:
          this.isWeekdayContact = undefined;
      }
      this.startTime = this.patientDetail.contactTime.split("-")[0];
      this.endTime = this.patientDetail.contactTime.split("-")[1] ?? "";
      //areaIDArray
      const provinceID = this.patientDetail.provinceId;
      const cityID = this.patientDetail.cityId;
      const areaID = this.patientDetail.areaId;
      if (provinceID != 0 || cityID == !0 || areaID == !0) {
        this.areaIDArray = [provinceID, cityID, areaID];
      }
      this.address = this.patientDetail.address;
      this.selectedMedicalType = this.patientDetail.illness;
      this.selectedMedicalHistory = this.patientDetail.ph
        ? this.patientDetail.ph.split(",")
        : "";
      this.medicalInfo = this.patientDetail.phi;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.panel-alert-content {
  padding-right: 24px;
  padding-bottom: 10px;
  padding-top: 0;
}

/deep/.panel-alert-bottom {
  border: none;
}

.add-patient-alert {
  &-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-right: 20px;
  }

  &-line {
    background-color: rgba($color: #000000, $alpha: 0.09);
    width: 100%;
    height: 1px;
    margin-top: 18px;
  }
}

.key-value-item {
  display: flex;
  align-items: center;
  margin-top: 18px;

  &-unit {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    height: 100%;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  /deep/.el-input__inner {
    font-size: 14px;
    height: 32px;
    width: 142px;
  }

  /deep/.el-input__icon {
    line-height: 32px;
  }

  /deep/.el-select-dropdown__item {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  &-birthday {
    /deep/.el-date-editor.el-input {
      width: 142px;
    }
  }

  &-bmi {
    &-key {
      color: rgba($color: #000000, $alpha: 0.85);
      font-size: 14px;
      width: 90px;
      box-sizing: border-box;
      padding-left: 5px;
    }

    &-value {
      color: rgba($color: #000000, $alpha: 0.85);
      font-size: 14px;
      width: 50px;
    }
  }

  &-area {
    /deep/.el-input__inner {
      width: 434px;
    }

    /deep/.el-cascader-menu__list{
      max-height: 200px;
    }
  }

  &-history {
    /deep/.el-input__inner {
      width: 200px;
    }
  }

  &-info {
    /deep/.must-label {
      height: 45px;
    }

    /deep/.el-textarea__inner {
      font-size: 14px;
      width: 434px;
      height: 76px !important;
      margin-top: 12px;
    }
  }
}

.address-item {
  /deep/.el-input__inner {
    margin-top: 18px;
    margin-left: 77px;
    font-size: 14px;
    width: 434px;
    height: 32px;
  }
}

.time-select-item {
  width: 240px;
  margin-top: 18px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-line {
    flex: 1;
    font-size: 14px;
    color: #6b6b6b;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/.el-input__icon {
    line-height: 32px;
  }

  /deep/.el-input__inner {
    height: 32px;
  }

  /deep/.el-select-dropdown__item {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
</style>
