<template>
  <div class="switch-picker">
    <button
      class="switch-picker-button left"
      :class="[isPickLeft == true ? 'blue' : 'gray']"
      :style="{ width: `${buttonWidth}px` }"
      @click="onLeftClick"
    >
      {{ left }}
    </button>
    <button
      class="switch-picker-button right"
      :class="[isPickLeft == false ? 'blue' : 'gray']"
      :style="{ width: `${buttonWidth}px` }"
      @click="onRightClick"
    >
      {{ right }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    left: String,

    right: String,

    buttonWidth: {
      type: Number,
      default: 70,
    },

    inputIsPickLeft: {
      type: Boolean,
      default: undefined,
    },
  },

  data: function () {
    return {
      isPickLeft: undefined,
    };
  },

  created: function () {
    this.isPickLeft = this.inputIsPickLeft;
  },

  watch: {
    inputIsPickLeft(newValue) {
      this.isPickLeft = newValue;
    },
  },

  methods: {
    onLeftClick: function () {
      this.isPickLeft = true;
      this.$emit("onPick", this.isPickLeft);
    },

    onRightClick: function () {
      this.isPickLeft = false;
      this.$emit("onPick", this.isPickLeft);
    },
  },
};
</script>

<style lang="scss" scoped>

.switch-picker {
  height: 32px;
  display: flex;

  &-button {
    font-size: 14px;
    width: 60px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.blue {
  color: white;
  background-color: #2e6be6;
  border: 1px solid #2e6be6;
}

.gray {
  color: rgba($color: black, $alpha: 0.65);
  background-color: white;
  border: 1px solid #e8e8e8;
}
</style>